.PageNotFound404MobilePage-container{
    background-image   : url('../../../assets/images/Pattern.png');
    margin-top: 90px;
    margin-bottom: 90px;
    .PageNotFound404MobilePage-section{
        text-align: center;
        // height: calc(100vh - 300px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num_404{
            font-size: 50px;
            font-weight: 600;
            letter-spacing: 1px;
            color: #cb007b;
            white-space: nowrap;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .img_404{
            width:50px;
            height: 150px;
            margin: 0px 20px 0px 20px;
        }
        .oops_404{
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 1px;
            color: #cb007b;
            white-space: nowrap
        }
        .missing_404{
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 1px;
            color: #000000;
            white-space: nowrap
        }
        .looking_404{
            font-size: 10px;
            font-weight: 600;
            letter-spacing: 1px;
            color: #000000;
            white-space: nowrap
        }
        .back_to_home_404{
            margin-top: 20px;
            background-color: #cb007b;
            color: #fff;
            text-shadow: none;
            background-image: none;
            font-size: 10px;
            padding: 8px;
            border-radius: 5px;
            &:hover{
                text-decoration: none;
            }
        }
        
    }
}
